<template>
    <!--components/Task/TagInput.vue-->
    <div class="d-flex">
        <!-- <TagList :tags="item.tags" v-if="props.readonly" target="_blank"/> -->
        <div class="selected-tags d-flex" v-if="finalTags.length > 0">
            <div v-for="(tag, index) in finalTags" 
            :key="index" 
            class="me-2 border py-1 px-0 rounded single-tag" 
            @mouseenter="setTagHover(index, true)" 
            @mouseleave="setTagHover(index, false)">
            <span class="px-2">{{ tag.name }}</span>
            <span :class="{ 'tag-remove-active': tagIsHovered[index] }" class="tag-remove" role="button" @click="removeTag(index)">
                <i class="fa-light fa-multiply"></i>
            </span>
            </div>
        </div>

        <div class="tag-input">
            <v-select 
            ref="tag_input_v_select_ref"
            multiple 
            taggable 
            :options="tagOptions" 
            @search:focus="tagInputFocused" 
            @search:blur="tag_input_blur"
            @option:selected="tagSelected" 
            v-model="selectedTags" 
            @search="onSearch" 
            :create-option="(selectedTags) => ({ name: selectedTags })" 
            label="name" 
            value="name" 
            class="tag-input-custom"
            placeholder="tags"
            track-by="name">
            <template #no-options="{}">
                <div class="tag-searching hidden">Searching...</div>
            </template>
            </v-select>
        </div>
    </div>
</template>
<script setup>
const emits = defineEmits(["add:tag"]);

const taskStore = useTaskStore();
const finalTags = ref([]);
let tagOptions = ref([]);
const selectedTags = ref([]);
const tagIsHovered = ref(finalTags.value.map(() => false));
const tag_input_v_select_ref = ref(null);

const tasks = computed( () =>{
  return taskStore.getTasks
})

let tag_input_value = '';
const onSearch = async(search, loading) => {
  if (search) {
    tag_input_value = search;
    setTimeout(async () => {
      let tags = await getAllTags(search);

      if (tags && Object.keys(tags).length > 0) {
        tagOptions.value = Object.keys(tags).map((key) => ({ name: key }));
      } else {
        tagOptions.value = [];
      }
    }, 300);
  }
};

const removeTag = (index) => {
  finalTags.value.splice(index, 1);
  emits("remove:tag", finalTags.value);
};

const tag_input_blur = () => {
  tag_input_value = tag_input_value.trim();
  if (tag_input_value != '') {
    let already_included = false;
    finalTags.value.forEach((item) => {
      if (item.name == tag_input_value) {
        already_included = true;
      }
    });
    if (! already_included) {
      tag_input_v_select_ref.value.select([{'name': tag_input_value}]);
    }
    tag_input_value = '';
    tag_input_v_select_ref.value.$el.querySelector('input.vs__search').value = '';
    tag_input_v_select_ref.value.closeSearchOptions();
  }
}

const tagSelected = (tag) => {
  finalTags.value.push(tag[0]);
  selectedTags.value = [];
  tag_input_value = '';
  emits("add:tag", finalTags.value);
};


const setTagHover = (index, value) => {
  tagIsHovered.value[index] = value;
};

const tagInputFocused = async () => {
  //vs1__listbox
  await nextTick();
  let listbox = document.getElementById("vs1__listbox");
  //listbox.style.display = "none";
};


const setTags = () => {
  tasks.value.forEach( (item) => {
    if(item.id === taskStore.getActiveTab.id && item.tags.length > 0){
      let tags = []
      item.tags.forEach( (tag) => {
        tags.push({'name': tag});
      })
      finalTags.value = tags;
    }
  })
}

watch(() => taskStore.activeTab, () => {
  setTags()
});

onMounted(()=>{
  setTags()
})
</script>

<style scoped>

.single-tag {
  position: relative;
}
::v-deep .vs__open-indicator {
  display: none;
}
.tag-searching {
  display: none;
  height: 0px;
}
.tag-input {
  width: 160px;
}

::v-deep .vs__search {
  color: grey !important;
  margin: 0px !important;
}
.vs__dropdown-toggle {
  padding: 0px !important;
}

.tag-remove {
  visibility: hidden;
  color: red;
  position: absolute;
  right: 6px;
  position: absolute;
  right: -7px;
  top: -12px;
}
.tag-remove-active {
  visibility: visible;
}

::v-deep .vs__actions{
  display: none !important;
}

::v-deep .vs__search::placeholder{
  color: rgba(0,0,0,0.6) !important;
}

.title_field {
  width: 100%;
}

.create_edit_view_task > * {
  margin-top: 0px;
  margin-bottom: 10px;
}

.tag {
  border: 1px solid #ccc;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 10px;
  margin-right: 2px;
}

.tag i {
  display: none;
  margin-left: 5px;
}

.tag.active i {
  display: initial;
  color: red;
  cursor: pointer;
}

.tag_input {
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* .tag-input-custom input::placeholder{
  color: #ccc !important;
} */

.command_box,
.code_box {
  background-color: #666666;
  color: white;
}

.command_box textarea,
.code_box textarea {
  background-color: transparent;
  color: white;
  width: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
}

.command_box textarea:focus,
.code_box textarea:focus {
  background-color: transparent;
  color: white;
  width: 100%;
  border: 0px solid transparent;
  outline: 0px solid transparent;
}

.create_edit_view_task_buttons_container {
  text-align: center;
}

.create_edit_view_task_buttons_container button {
  margin: 5px;
}

.quill_editor {
  height: 100px !important;
  background: white;
  margin-bottom: 20px !important;
}


.custom-tool-area{
  position: absolute; 
  top: 5px; 
  right: 5px; 
  z-index:1;
}

.read_only .title_field {
  display: none !important;
}

.custom-select {
  padding: 5px;
  border-radius: 5px;
  /* border: 1px solid lightgray; */
  border: none;
}

.read_only .custom-select {
  display: none !important;
}

.read_only .tag_input {
  display: none !important;
}

.read_only .ql-editor {
  background-color: #f3f3f3 !important;
}


.code_mirror_syntax_check_result {
  padding-left: 5px;
}
.codemirror_container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.metadata_container {
  text-align: left;
  padding-right: 5px;
  font-size: 13px;
  color: gray;
  /* font-style: italic; */
  font-weight: 300;  
}

.code-editor-btn{
  right: 5px;
  bottom: 5px;
  border: 1px solid var(--check-syntax-btn-border);
  background: var(--check-syntax-btn-background);
  border: none;
  border-radius: 5px;
}
#codeEditModal .modal-header {
	padding: 0px 10px;
}

#codeEditModal .codemirror_container {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 2px;
  margin-right: 2px;
  outline: none;
}
#codeEditModal .btn-outline-primary {
  border: 0px;
}
#codeEditModal .btn:hover {
  background-color: white;
  color: var(--bs-btn-color);
}

#codeEditModal .modal-body {
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
}
</style>

<style>
@import "vue-select/dist/vue-select.css";
</style>
